<template>
	<div>
		<page-header
			:menu="menu"
			:primary-button="{ label: 'Save', type: 'button' }"
			:execute-method="true"
			:method="save"
			:loading="loading"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
			<!-- Alert Box -->
			<Alert
				class="tw-my-3"
				type="error"
				:messages="errors"
			/>
			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						General Information
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						In this section you will be able to update the organization name and description.
					</p>
					<p class="tw-mt-3 tw-text-sm tw-text-gray-500">
						<a
							:href="`/organization/@${organization.slug}`"
							class="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
						>
							View the organization public profile
						</a>
					</p>

					<p class="tw-italic tw-text-xs tw-mt-3">
						<svg
							class="tw-flex-shrink-0 tw-h-4 tw-w-4 tw-text-gray-400 tw-inline-block"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							stroke="currentColor"
							fill="none"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
						Please note that the organization profile will only be visible to others if at least one public package has been published.
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
					<div class="tw-flex-grow tw-space-y-6">
						<div>
							<label
								for="name"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Name
							</label>
							<input
								v-model="organization.name"
								type="text"
								name="name"
								id="name"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							>
						</div>

						<div>
							<label
								for="slug"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Slug
							</label>
							<input
								v-model="organization.slug"
								type="text"
								name="slug"
								id="slug"
								class="tw-mt-1 focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-shadow-sm sm:tw-text-sm tw-border-gray-300 tw-rounded-md tw-opacity-75"
								disabled
							>
						</div>

						<div>
							<label
								for="description"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Description
							</label>
							<textarea
								v-model="organization.description"
								id="description"
								name="description"
								rows="4"
								placeholder="Organization description..."
								class="tw-block tw-w-full tw-shadow-sm focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
							/>
						</div>
					</div>
					<div class="tw-mt-6 tw-flex-grow lg:tw-mt-0 lg:tw-ml-6 lg:tw-flex-grow-0 lg:tw-flex-shrink-0">
						<div class="tw-relative tw-rounded-full tw-overflow-hidden tw-h-32 tw-w-32">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								class="tw-flex-shrink-0 tw-h-32 tw-w-32 tw-rounded-full tw-border-2 tw-p-3 tw-text-gray-400"
							><path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
							/></svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props : {
		user : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	components : {},
	data() {
		return {
			menu         : [ { label: "Profile" } ],
			loading      : false,
			errors       : [],
			notification : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	methods : {
		save() {
			var app = this;
			app.errors = [];
			app.notification.show = false;
			const data = new FormData();
			data.append( "name", app.organization.name );
			data.append( "description", app.organization.description );
			app.$http.post(
				`/orgs/${app.organization.slug}/settings/saveProfile`, data
			)
				.then( ( response ) => {
					app.loading = false;
					if ( !response.data.error ) {
						app.notification = {
							type    : "success",
							title   : "Success",
							message : response.data.data,
							show    : true
						};
					} else {
						app.errors.push( response.data.messages[0] );
					}
				} ).catch( function( error ) {
					// handle error
					app.loading = false;
					app.errors = [ error.response.data.messages[0] ];
					app.notification = {
						type    : "error",
						title   : "Error",
						message : "Oops something went wrong. Please try again",
						show    : true
					};
				} );
		}
	}
};
</script>