<template>
	<tr>
		<td
			class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-gray-900"
		>
			<div class="tw-flex tw-items-center">
				<div class="tw-flex-shrink-0 tw-h-10 tw-w-10">
					<a
						:href="'@'+collaboration.user.username"
						class="tw-flex"
					>
						<span class="tw-inline-block tw-relative">
							<img
								:src="collaboration.user.avatarLink"
								:alt="`${collaboration.user.fullName}`"
								class="tw-rounded-full tw-mr-3 tw-inline-block"
							>
						</span>
					</a>
				</div>
				<div class="tw-ml-4">
					<div class="tw-text-sm tw-font-medium tw-text-gray-900">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="tw-inline-block tw-h-3 tw-w-3 tw-rounded-full tw-ring-2 tw-ring-white tw-cursor-default"
							:class="{ 'tw-bg-green-500' : getStatusLabel() == 'Accepted', 'tw-bg-red-500' : getStatusLabel() == 'Rejected', 'tw-bg-yellow-400' : getStatusLabel() == 'Pending' }"
							fill="none"
							viewBox="0 0 24 24"
							stroke="white"
							v-tooltip="getStatusLabel() == 'Accepted' ? 'Active Collaborator' : getStatusLabel() == 'Pending' ? 'Pending Invite' : 'Rejected Invite'"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								:d="getStatusLabel() == 'Accepted' ? 'M5 13l4 4L19 7' : getStatusLabel() == 'Pending' ? '' : 'M6 18L18 6M6 6l12 12'"
							/>
						</svg>
						{{ collaboration.user.fullName }}
					</div>
					<div class="tw-text-sm tw-text-gray-500">
						@{{ collaboration.user.username }}
					</div>
				</div>
			</div>
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
			v-tooltip="getFullDate(collaboration.createdDate)"
		>
			{{ collaboration.createdDate | formatDate }}
		</td>
		<td
			class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
			v-tooltip="getFullDate(collaboration.respondedDate)"
		>
			{{ collaboration.respondedDate | formatDate }}
		</td>
		<td
			class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
		>
			<span
				:class="collaboration.publishAccess ? 'tw-bg-green-500' : 'tw-bg-red-500'"
				class="tw-w-3 tw-h-3 tw-mr-4 tw-rounded-full tw-inline-block tw-mx-auto tw-cursor-pointer"
				v-tooltip="collaboration.publishAccess ? 'Yes' : 'No'"
			/>
		</td>
		<td class="tw-pr-6">
			<div
				v-click-outside="() => (showDropdown = false)"
				class="tw-relative tw-flex tw-justify-end tw-items-center"
			>
				<button
					id="package-options-menu-0"
					aria-haspopup="true"
					type="button"
					class="tw-w-8 tw-h-8 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 tw-rounded-full hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
					@click="showDropdown = !showDropdown"
				>
					<span class="tw-sr-only">Open dropdown</span>
					<!-- Heroicon name: solid/dots-vertical -->
					<svg
						class="tw-w-5 tw-h-5"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
					</svg>
				</button>
				<transition
					enter-class="tw-opacity-0 tw-transform tw-scale-95"
					enter-to-class="tw-opacity-100 tw-transform tw-scale-100"
					enter-active-class="tw-transition ease-out duration-200"
					leave-class="tw-opacity-100 tw-transform tw-scale-100"
					leave-to-class="tw-opacity-0 tw-transform tw-scale-95"
					leave-active-class="tw-transition tw-ease-in tw-duration-75"
				>
					<div
						v-if="showDropdown"
						class="tw-mx-3 tw-origin-top-right tw-absolute tw-right-7 tw-top-0 tw-w-52 tw-mt-1 tw-rounded-md tw-shadow-lg tw-z-10 tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-divide-y tw-divide-gray-200"
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="package-options-menu-0"
					>
						<div
							class="tw-py-1"
							role="none"
						>
							<a
								v-if="getStatusLabel() == 'Pending' || getStatusLabel() == 'Rejected'"
								:href="'/myaccount/package/editVersion/' +collaboration.collaborationID"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900"
								role="menuitem"
							>
								<!-- Heroicon name: solid/plane -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
										clip-rule="evenodd"
									/>
								</svg>

								Resend
							</a>
							<span
								v-if="getStatusLabel() == 'Pending'"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="openRevokeDialog"
							>
								<!-- Heroicon name: color-x -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								Revoke
							</span>

							<span
								v-if="getStatusLabel() == 'Accepted'"
								class="tw-group tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-cursor-pointer"
								@click="openRemovalDialog"
							>
								<!-- Heroicon name: solid/trash -->
								<svg
									class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
								Remove Collaborator
							</span>
						</div>
					</div>
				</transition>
			</div>
		</td>
	</tr>
</template>

<script>
import ClickOutside from "vue-click-outside";
import swal from "sweetalert2";

export default {
	props : {
		collaboration : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			showDropdown  : false,
			publishAccess : !this.collaboration.publishAccess,
			loading       : false,
			errors        : []
		};
	},
	directives : { ClickOutside },
	methods    : {
		getStatusLabel() {
			if ( !this.collaboration.accepted && this.collaboration.respondedDate == "" ) {
				return "Pending";
			}
			else if ( !this.collaboration.accepted && this.collaboration.respondedDate != "" ) {
				return "Rejected";
			}
			else if ( this.collaboration.accepted ) {
				return "Accepted";
			}
		},
		openRemovalDialog() {
			var app = this;
			app.loading = true;
			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to remove this collaborator from this package. This action cannot be undone.",
				icon               : "warning",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#e02424",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.delete(
						`/api/v1/collaborations/${app.collaboration.collaborationID}`
					)
						.then( ( response ) => {
							app.loading = false;
							if ( !response.data.error ) {
								app.removeCollaborator( app.collaboration );
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : "The collaborator has been removed successfully",
									show    : true
								};
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
							}
						} ).catch( error => {
							app.loading = false;
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : error.response.data.messages[0],
								show    : true
							};
							app.errors.push( error.response.data.messages[0] );
						} );;
				}
			} );
		},
		openRevokeDialog() {
			var app = this;
			app.loading = true;
			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to revoke the invite to collaborate on this package.",
				icon               : "warning",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#e02424",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.delete(
						`/api/v1/collaborations/${app.collaboration.collaborationID}`
					)
						.then( ( response ) => {
							app.loading = false;
							if ( !response.data.error ) {
								app.$parent.removeCollaboration( app.collaboration.collaborationID );
								app.$parent.notification = {
									type    : "success",
									title   : "Success",
									message : "The invitation has been revoked!",
									show    : true
								};
							} else {
								app.$parent.notification = {
									type    : "error",
									title   : "Error",
									message : response.data.messages[0],
									show    : true
								};
							}
						} ).catch( error => {
							console.log( error );
							app.loading = false;
							app.$parent.notification = {
								type    : "error",
								title   : "Error",
								message : "Oops something went wrong. Please try again",
								show    : true
							};
						} );;
				}
			} );
		},
		removeCollaborator( collaboration ) {
			var app = this;
			app.$parent.collaborations = app.$parent.collaborations.filter(
				c => c.collaborationID !== collaboration.collaborationID
			);
			// Verify this, we need to update tab count
			// app.$parent.thisPackage.collaborations = app.$parent.collaborations;
		}
	}
};
</script>
