<template>
	<div>
		<main
			class="tw-flex-1 tw-relative tw-z-0 tw-overflow-y-auto focus:tw-outline-none"
			tabindex="0"
		>
			<page-header />

			<flash-messages
				v-if="notification.show"
				:type="notification.type"
				:title="notification.title"
				:message="notification.message"
			/>

			<header class="tw-bg-gray-100 tw-py-4">
				<div
					class="tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 xl:tw-flex xl:tw-items-center xl:tw-justify-between"
				>
					<div class="tw-flex-1 tw-min-w-0">
						<h1
							v-if="!isOrg"
							class="tw-mt-2 tw-text-2xl tw-font-bold tw-leading-7 tw-text-gray-900 sm:tw-text-3xl sm:tw-truncate"
						>
							{{
								getSaludationText() + ", " + user.fname
							}}
						</h1>
						<h1
							v-else
							class="tw-mt-2 tw-text-2xl tw-font-bold tw-leading-7 tw-text-gray-900 sm:tw-text-3xl sm:tw-truncate"
						>
							<a
								:href="`/organization/@${organization.slug}`"
								class="hover:tw-text-gray-500"
							>
								{{ organization.name }}
							</a>
						</h1>

						<div
							class="tw-mt-1 tw-flex tw-flex-col sm:tw-flex-row sm:tw-flex-wrap sm:tw-mt-0 sm:tw-space-x-8"
						>
							<div
								class="tw-mt-1 tw-flex tw-items-center tw-text-sm tw-font-bold tw-text-gray-500"
							>
								{{ !isOrg ? user.jobTitle : organization.description }}
							</div>
						</div>
					</div>
					<div class="tw-flex-shrink">
						<div class="tw-space-y-2">
							<div class="tw-space-x-1">
								<svg
									class="tw-inline-block tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
										clip-rule="evenodd"
									/>
								</svg>
								<span class="tw-text-sm tw-font-medium tw-text-gray-500">
									Last Login: {{ user.lastLogin | formatDate }}
								</span>
							</div>

							<div class="tw-space-x-1">
								<svg
									class="tw-inline-block tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
									v-tooltip="user.lastLoginAgent"
								>
									<path
										fill-rule="evenodd"
										d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
										clip-rule="evenodd"
									/>
								</svg>
								<span class="tw-text-sm tw-font-medium tw-text-gray-500">
									IP Address: {{ user.lastLoginIp }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</header>
			<!-- Main Content -->
			<div class="tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-my-3">
				<h2 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
					Overview
				</h2>
				<div
					class="tw-mt-2 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-3"
				>
					<!-- Packages Card -->
					<div
						class="tw-bg-gray-100 tw-overflow-hidden tw-shadow tw-rounded-lg"
					>
						<div class="tw-p-5">
							<div class="tw-flex tw-items-center">
								<div class="tw-flex-shrink-0">
									<!-- Heroicon name: outline/scale -->
									<svg
										class="tw-h-20 tw-w-20 tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
										/>
									</svg>
								</div>
								<div class="tw-ml-5 tw-w-0 tw-flex-1">
									<dl>
										<dt>
											<a
												:href="
													!isOrg
														? '/myaccount/packages'
														: `/orgs/${organization.slug}/packages`
												"
												class="tw-text-md tw-font-bold tw-text-gray-500 hover:tw-text-gray-400"
											>
												Packages
											</a>
										</dt>
										<dd>
											<div class="tw-text-sm tw-font-medium tw-text-gray-500">
												Public: {{ packagesCount.public }}<br />
												Private: {{ packagesCount.private }}
											</div>
										</dd>
									</dl>
								</div>
							</div>
						</div>
						<div class="tw-bg-gray-200 tw-px-5 tw-py-3 tw-shadow ">
							<div class="tw-text-sm tw-text-center">
								<a
									:href="
										!isOrg
											? '/myaccount/packages'
											: `/orgs/${organization.slug}/packages`
									"
									class="tw-font-bold tw-text-blue-500 hover:tw-text-blue-400"
								>
									View All
								</a>
							</div>
						</div>
					</div>

					<!-- Collaborations Card -->
					<div
						class="tw-bg-gray-100 tw-overflow-hidden tw-shadow tw-rounded-lg"
					>
						<div class="tw-p-5">
							<div class="tw-flex tw-items-center">
								<div class="tw-flex-shrink-0">
									<!-- Heroicon name: outline/scale -->
									<svg
										class="tw-h-20 tw-w-20 tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
										/>
									</svg>
								</div>
								<div class="tw-ml-5 tw-w-0 tw-flex-1">
									<dl>
										<dt>
											<a
												:href="
													!isOrg
														? '/myaccount/collaborations'
														: `/orgs/${organization.slug}/collaborations`
												"
												class="tw-text-md tw-font-bold tw-text-gray-500 hover:tw-text-gray-400"
											>
												Collaborations
											</a>
										</dt>
										<dd>
											<div class="tw-text-sm tw-font-medium tw-text-gray-500">
												Collaborating on
												{{ packagesCount.collaborations }} packages
											</div>
										</dd>
									</dl>
								</div>
							</div>
						</div>
						<div class="tw-bg-gray-200 tw-px-5 tw-py-3 tw-shadow ">
							<div class="tw-text-sm tw-text-center">
								<a
									:href="
										!isOrg
											? '/myaccount/collaborations'
											: `/orgs/${organization.slug}/collaborations`
									"
									class="tw-font-bold tw-text-blue-500 hover:tw-text-blue-400"
								>
									View All
								</a>
							</div>
						</div>
					</div>

					<!-- Organizations Card for user context-->
					<div
						v-if="!isOrg"
						class="tw-bg-gray-100 tw-overflow-hidden tw-shadow tw-rounded-lg"
					>
						<div class="tw-p-5">
							<div class="tw-flex tw-items-center">
								<div class="tw-flex-shrink-0">
									<!-- Heroicon name: outline/scale -->
									<svg
										class="tw-h-20 tw-w-20 tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
										/>
									</svg>
								</div>
								<div class="tw-ml-5 tw-w-0 tw-flex-1">
									<dl>
										<dt>
											<a
												href="/myaccount/organizations"
												class="tw-text-md tw-font-bold tw-text-gray-500 hover:tw-text-gray-400"
											>
												Organizations
											</a>
										</dt>
										<dd>
											<div class="tw-text-sm tw-font-medium tw-text-gray-500">
												<span v-if="organizationsCount > 0">
													You belong to {{ organizationsCount }} organization{{ organizationsCount > 1 ? 's' : '' }}
												</span>
												<span v-else>
													You are not part of any organization yet
												</span>
											</div>
										</dd>
									</dl>
								</div>
							</div>
						</div>
						<div class="tw-bg-gray-200 tw-px-5 tw-py-3 tw-shadow ">
							<div class="tw-text-sm tw-text-center">
								<a
									href="/myaccount/organizations"
									class="tw-font-bold tw-text-blue-500 hover:tw-text-blue-400"
								>
									View All
								</a>
							</div>
						</div>
					</div>

					<!-- Teams Card for the org context -->
					<div
						v-else
						class="tw-bg-gray-100 tw-overflow-hidden tw-shadow tw-rounded-lg"
					>
						<div class="tw-p-5">
							<div class="tw-flex tw-items-center">
								<div class="tw-flex-shrink-0">
									<!-- Heroicon name: user-group -->
									<svg
										class="tw-h-20 tw-w-20 tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
										/>
									</svg>
								</div>
								<div class="tw-ml-5 tw-w-0 tw-flex-1">
									<dl>
										<dt>
											<a
												:href="`/orgs/${organization.slug}/members`"
												class="tw-text-md tw-font-bold tw-text-gray-500 hover:tw-text-gray-400"
											>
												Members
											</a>
										</dt>
										<dd>
											<div class="tw-text-sm tw-font-medium tw-text-gray-500">
												Confirmed Members: {{ organization.numberOfConfirmedMembers }} <br />
												Unconfirmed Members: {{ organization.numberOfPendingMembers }}
											</div>
										</dd>
									</dl>
								</div>
							</div>
						</div>
						<div class="tw-bg-gray-200 tw-px-5 tw-py-3 tw-shadow ">
							<div class="tw-text-sm tw-text-center">
								<a
									:href="`/orgs/${organization.slug}/members`"
									class="tw-font-bold tw-text-blue-500 hover:tw-text-blue-400"
								>
									View All
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="tw-mt-6">
					<div class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-2">
						<div>
							<h2
								class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
							>
								{{ !isOrg ? "My " : "" }}Most Popular Packages
							</h2>
							<div
								class="tw-bg-gray-100 tw-overflow-hidden tw-shadow tw-rounded-lg tw-mt-3"
							>
								<top-packages
									:packages="topPackages"
									:organization="organization"
									:is-org="isOrg"
								/>
							</div>
						</div>

						<div v-if="!isOrg">
							<h2
								class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
							>
								Pending Invites
							</h2>
							<div class="tw-overflow-hidden tw-shadow tw-rounded-lg tw-mt-3">
								<pending-invites
									:collabs="collaborationInvites"
									:orgs="organizationInvites"
								/>
							</div>
						</div>
						<div v-else>
							<h2
								class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
							>
								Top Teams
								<a
									v-if="organization.numberOfTeams > 5"
									:href="`/orgs/${organization.slug}/teams`"
									class="tw-font-medium tw-text-sm tw-text-blue-500 hover:tw-text-blue-400"
								>
									(View All)
								</a>
							</h2>
							<div
								class="tw-bg-gray-100 tw-overflow-hidden tw-shadow tw-rounded-lg tw-mt-3"
							>
								<teams :organization="organization" />
							</div>
						</div>
					</div>
				</div>

				<div class="tw-flex tw-flex-col tw-my-6">
					<h2 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
						{{ !isOrg ? "My" : "" }} Activity Log
						<a
							:href="
								!isOrg
									? '/myaccount/activityLog'
									: '/orgs/' + organization.slug + '/activityLog'
							"
							class="tw-font-medium tw-text-sm tw-text-blue-500 hover:tw-text-blue-400"
						>
							(View Full Activity)
						</a>
					</h2>
					<activity-log
						:organization="organization"
						:is-org="isOrg"
						class="tw-mt-3"
					/>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import TopPackages from "./TopPackages";
import PendingInvites from "./PendingInvites";
import Teams from "./Teams";
import ActivityLog from "./ActivityLog";

export default {
	props : {
		user : {
			type     : Object,
			required : true
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		organizationsCount : {
			type     : Number,
			required : false,
			default  : 0
		},
		packagesCount : {
			type     : Object,
			required : true,
			default  : function() {
				return {
					all            : 0,
					public         : 0,
					private        : 0,
					collaborations : 0
				};
			}
		},
		membersCount : {
			type     : Object,
			required : false,
			default  : function() {
				return {
					confirmed : 0,
					pending   : 0,
				};
			}
		},
		orgs : {
			type     : Object,
			required : false,
			default  : function() {
				return {
					orgs  : [],
					count : 0
				};
			}
		},
		topPackages : {
			type     : Array,
			required : true
		},
		collaborationInvites : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		organizationInvites : {
			type     : Array,
			required : false,
			default  : function() {
				return [];
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : {
		TopPackages,
		PendingInvites,
		Teams,
		ActivityLog
	},
	data() {
		return {
			loading      : false,
			errors       : [],
			notification : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	computed : {
		totalMembers() {
			return this.membersCount.confirmed + this.membersCount.pending;
		},
		myOrgs() {
			var app = this;
			return [];
			return app.orgs.orgs.filter(
				org => org.owner.username == app.user.username
			);
		},
		confirmedMembers() {
			var app = this;
			return [];
			return app.isOrg
				? app.organization.members.filter( m => m.isAccepted )
				: [];
		},
		unConfirmedMembers() {
			var app = this;
			return [];
			return app.isOrg
				? app.organization.members.filter( m => !m.isAccepted )
				: [];
		}
	},
	methods : {
		getSaludationText() {
			// var today = new Date();
			var curHr = new Date().getHours();
			if ( curHr < 12 ) {
				return "Good Morning";
			} else if ( curHr < 18 ) {
				return "Good Afternoon";
			} else {
				return "Good Evening";
			}
		}
	}
};
</script>
