<template>
	<div>
		<div class="tw-align-middle tw-inline-block tw-min-w-full tw-border-b tw-border-gray-100">
			<div
				v-if="teams.length == 0 && !loading"
				class="tw-my-3"
			>
				<div class="tw-px-4 tw-py-5 sm:tw-p-6">
					<p class="tw-text-md tw-font-bold tw-text-gray-500">
						No teams yet
					</p>
					<div class="tw-mt-2 tw-text-sm tw-text-gray-500">
						<p class="tw-text-sm">
							Currently, there are no teams on this organization
						</p>
					</div>
				</div>
			</div>
			<div
				v-else-if="loading"
				class="tw-flex tw-my-3 tw-ml-3"
			>
				<div class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3">
					<div class="tw-btn-spinner tw-mt-1" />
					<span class="tw-ml-2">Loading...</span>
				</div>
			</div>
			<div v-else>
				<table class="tw-min-w-full">
					<thead>
						<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
							<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-text-center">
								#
							</th>
							<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
								Name
							</th>
							<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center">
								Members
							</th>
							<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center">
								Packages
							</th>
							<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
								Actions
							</th>
						</tr>
					</thead>
					<tbody class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow">
						<tr
							v-for="(team,index) in teams"
							:key="team.teamId"
							class="hover:tw-bg-gray-50"
						>
							<td class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
								{{ index + 1 }}
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
							>
								{{ team.name }}
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
							>
								<span class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-blue-300 tw-text-gray-800">
									{{ team.numberOfMembers }}
								</span>
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
							>
								<span class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-blue-300 tw-text-gray-800">
									{{ team.numberOfEntries }}
								</span>
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
							>
								<!-- View button -->
								<a
									:href="`/orgs/${organization.slug}/teams/manage/${team.teamId}`"
									class="tw-text-sm tw-text-gray-700 hover:tw-text-gray-900 tw-inline-block"
									role="menuitem"
								>
									<!-- Heroicon name: eye -->
									<svg
										class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										aria-hidden="true"
										v-tooltip="'View'"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
										/>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
										/>
									</svg>
								</a>
								<!-- Probably show manage button if the user have permissions to do so -->
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props : {
		organization : {
			type     : Object,
			required : true
		},
	},
	data() {
		return {
			teams   : [],
			loading : false,
			errors  : []
		};
	},
	mounted(){
		var app = this;
		app.getTeams();
	},
	methods : {
		getTeams(){
			let app = this;
			app.errors = [];
			app.loading = true;

			app.$http.get(
				`/orgs/${app.organization.slug}/teams/list`, { params: { max: 5 } }
			)
				.then( response => {
					app.teams = response.data.data;
					app.loading = false;
				} )
				.catch( err => {
					app.loading = false;
					app.errors.push( "The teams list couldn't be retrieved. Please try again" );
				} );
		}
	}
};
</script>