<template>
	<div>
		<page-header
			:menu="menu"
		/>
		<new-team
			:show-slideover="showNewTeam"
			:organization="organization"
			@interface="showNewTeam = $event"
		/>
		<edit-team
			:edit-mode="true"
			:team="teamToEdit"
			:organization="organization"
			:show-slideover="showEditTeam"
			@interface="showEditTeam = $event"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-px-4 tw-my-6 sm:tw-px-6 lg:tw-px-8">
			<div
				v-if="teams.length == 0 && !loading"
				class="tw-shadow tw-bg-white tw-p-3 tw-rounded-lg"
			>
				<div class="tw-px-4 tw-py-5 sm:tw-p-6">
					<p class="tw-text-md tw-font-bold tw-text-gray-500">
						There are no teams created yet
					</p>
					<div class="tw-mt-2 tw-text-sm tw-text-gray-500">
						<p class="tw-text-sm">
							Currently, there are no teams in this organization.
						</p>
						<p class="tw-text-sm">
							Create a team
							<span
								class="tw-text-blue-600 hover:tw-text-blue-500 tw-cursor-pointer"
								@click="showNewTeam = true"
							>
								now!
							</span>
						</p>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="tw-flex tw-justify-between tw-gap-3">
					<div class="tw-w-full">
						<label
							for="teamsSearch"
							class="tw-sr-only"
						>
							Search
						</label>
						<div class="tw-relative tw-rounded-md tw-shadow-sm">
							<div class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none">
								<!-- Heroicon name: solid/search -->
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
							<input
								v-model="search"
								name="teamsSearch"
								id="teamsSearch"
								type="search"
								class="focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-pl-10 sm:tw-text-sm tw-border-gray-300 tw-rounded-md"
								placeholder="Search"
							>
							<!-- Heroicon name: x -->
							<div
								v-if="search != ''"
								class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-cursor-pointer"
							>
								<svg
									class="tw-h-5 tw-w-5 tw-text-gray-400 hover:tw-text-gray-300"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
									@click="clearSearch"
								>
									<path
										fill-rule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div
						v-if="canManageTeam"
						class="tw-flex-inline"
					>
						<div class="tw-flex tw-gap-3">
							<button
								type="button"
								class="tw-w-48 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md tw-text-white tw-bg-blue-700 hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
								@click="showNewTeam = true"
							>
								Create Team
							</button>
						</div>
					</div>
				</div>

				<div
					v-if="teams.length != 0 && !loading"
					class="tw-flex tw-mt-3"
				>
					<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
						Showing <span class="tw-font-bold">all</span> teams
					</div>
					<div class="tw-flex-shrink-0 tw-text-sm tw-text-gray-500">
						Total Teams: {{ teams.length }}
					</div>
				</div>

				<div
					v-else-if="loading"
					class="tw-flex tw-mt-3"
				>
					<div class="tw-flex-1 tw-text-sm tw-text-gray-500">
						<div
							class="tw-flex tw-text-gray-500 tw-text-md tw-mt-3"
						>
							<div
								class="tw-btn-spinner tw-mt-1"
							/>
							<span class="tw-ml-2">Loading...</span>
						</div>
					</div>
				</div>

				<div class="tw-bg-white">
					<!-- Packages table (small breakpoint and up) -->
					<div class="tw-mt-3">
						<div class="tw-w-full tw-border-b tw-border-gray-100 tw-shadow">
							<table class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100">
								<thead>
									<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
										<th class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
											<span class="lg:tw-pl-2">
												Name
											</span>
										</th>
										<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
											Members
										</th>
										<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
											Packages
										</th>
										<th class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase">
											Created On
										</th>
										<th class="tw-pr-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider" />
									</tr>
								</thead>
								<tbody class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow">
									<tr
										v-if="filteredTeams.length === 0 && !loading"
										class="tw-bg-gray-50"
									>
										<td
											colspan="7"
											class="tw-pb-6 tw-text-center"
										>
											<svg
												class="tw-h-32 tw-mx-auto tw-text-gray-600 tw-w-72 tw-mt-3"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
												/>
											</svg>
											<p class="tw-text-2xl tw-font-bold tw-text-gray-700 tw-leading-7 sm:tw-text-3xl sm:tw-leading-9 sm:tw-truncate">
												No teams found
											</p>
										</td>
									</tr>
									<tr
										v-for="(team,index) in filteredTeams"
										is="TeamRow"
										:team="team"
										:member="member"
										:organization="organization"
										:can-manage-team="canManageTeam"
										:key="team.teamId+'-'+index"
										class="hover:tw-bg-gray-50"
									/>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TeamRow from "./TeamRow";
import NewTeam from "./New";
import EditTeam from "./Edit";

export default {
	props : {
		member : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	components : {
		TeamRow,
		NewTeam,
		EditTeam
	},
	data() {
		return {
			menu         : [ { label: "Teams" } ],
			showNewTeam  : false,
			showEditTeam : false,
			teamToEdit   : {},
			teams        : [],
			search       : "",
			loading      : false,
			errors       : [],
			mainFilter   : "all",
			notification : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	computed : {
		filteredTeams(){
			return this.teams.filter( team => this.localSearch( team ) );
		},
		canManageTeam(){
			return this.member.role.role == "Super Admin" || this.member.role.role == "Admin";
		}
	},
	mounted(){
		var app = this;
		app.getTeams();
	},
	methods : {
		getTeams(){
			let app = this;
			app.errors = [];
			app.loading = true;

			app.$http.get(
				`/orgs/${app.organization.slug}/teams/list`
			)
				.then( response => {
					app.teams = response.data.data;
					app.loading = false;
				} )
				.catch( err => {
					app.loading = false;
					app.errors.push( "The teams list couldn't be retrieved. Please try again" );
				} );
		},
		filterResults( filter ) {
			let app = this;
			app.mainFilter = filter;
		},
		clearSearch() {
			let app = this;
			app.search = "";
		},
		resetFilters() {
			let app = this;
			app.search = "";
		},
		localSearch( team ) {
			var app = this;
			if ( ( team.name.toLowerCase().includes( app.search.toLowerCase() ) || team.description.toLowerCase().includes( app.search.toLowerCase() ) ) || app.search == "" ) {
				return true;
			} else {
				return false;
			}
		}
	}
};
</script>