<template>
	<div style="overflow='scroll' ">
		<div class="tw-align-middle tw-min-w-full tw-border-b tw-border-gray-100">
			<div
				v-if="packages.length == 0"
				class="tw-my-3"
			>
				<div class="tw-px-4 tw-py-5 sm:tw-p-6">
					<p class="tw-text-md tw-font-bold tw-text-gray-500">
						No published packages
					</p>
					<div class="tw-mt-2 tw-text-sm tw-text-gray-500">
						<p class="tw-text-sm">
							Currently, you don't have any published packages in your account
						</p>
						<p class="tw-text-sm">
							Go ahead and publish your first package
							<a
								:href="!isOrg ? '/myaccount/packages/new' : `/orgs/${organization.slug}/packages/new`"
								class="tw-text-blue-600 hover:tw-text-blue-500"
							>
								here!
							</a>
						</p>
					</div>
				</div>
			</div>
			<div
				class="tw-overflow-x-scroll lg:tw-overflow-x-hidden"
				v-else
			>
				<table class="tw-min-w-full tw-overflow-x-scroll">
					<thead>
						<tr class="tw-rounded-lg tw-shadow tw-bg-gray-100">
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-text-center"
							>
								#
							</th>
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
							>
								Package
							</th>
							<th
								class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
							>
								Installs
							</th>
							<th
								class="tw-hidden md:tw-table-cell tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-center tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
							>
								Actions
							</th>
						</tr>
					</thead>
					<tbody
						class="tw-bg-white tw-divide-y tw-divide-gray-100 tw-border tw-border-gray-100 tw-shadow"
					>
						<tr
							v-for="(thisPackage, index) in packages"
							:key="thisPackage.entryID"
							class="hover:tw-bg-gray-50"
						>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
							>
								{{ index + 1 }}
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
							>
								{{ thisPackage.title }}
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500"
							>
								{{ thisPackage.installs | formatNumber }}
							</td>
							<td
								class="md:tw-table-cell tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
							>
								<!-- View button -->
								<a
									:href="'/view/' + thisPackage.slug"
									class="tw-text-sm tw-text-gray-700 hover:tw-text-gray-900 tw-inline-block"
									role="menuitem"
								>
									<!-- Heroicon name: eye -->
									<svg
										class="tw-mr-3 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										aria-hidden="true"
										v-tooltip="'View'"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
										/>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
										/>
									</svg>
								</a>
								<!-- Manage Package -->
								<a
									:href="!isOrg ? `/myaccount/packages/manage/${thisPackage.slug}` : `/orgs/${organization.slug}/packages/manage/${thisPackage.slug}`"
									class="tw-text-sm tw-text-gray-700 hover:tw-text-gray-900 tw-inline-block"
									role="menuitem"
								>
									<!-- Heroicon name: solid/pencil-alt  -->
									<svg
										class="tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
										v-tooltip="'Manage'"
									>
										<path
											d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
										/>
										<path
											fill-rule="evenodd"
											d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
											clip-rule="evenodd"
										/>
									</svg>
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props : {
		packages : {
			type     : Array,
			required : true
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	methods : {}
};
</script>
