<template>
	<div>
		<page-header
			:menu="menu"
		/>
		<edit-package
			v-show="showEditPackage"
			:showslideover="showEditPackage"
			:this-package="thisPackage"
			:organization="organization"
			:is-org="isOrg"
			:is-admin="isAdmin"
			:has-private-packages="hasPrivatePackages"
			:can-publish-free-private-package="canPublishFreePrivatePackage"
			:package-types="packageTypes"
			@interface="showEditPackage = $event"
		/>
		<collaborators-form
			v-show="showCollaboratorsForm"
			:showslideover="showCollaboratorsForm"
			:this-package="thisPackage"
			:organization="organization"
			:is-org="isOrg"
			@interface="showCollaboratorsForm = $event"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div>
			<header class="tw-bg-gray-100 tw-py-4">
				<div class="tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 xl:tw-flex xl:tw-items-center xl:tw-justify-between">
					<div class="tw-flex-1 tw-min-w-0">
						<h1 class="tw-mt-2 tw-text-2xl tw-font-bold tw-leading-7 tw-text-gray-900 sm:tw-text-3xl sm:tw-truncate">
							<svg
								:class="thisPackage.isPrivate ? 'tw-text-red-600' : 'tw-text-green-500'"
								class="tw-inline-block tw-h-8 tw-w-8"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
								v-tooltip="thisPackage.isPrivate ? 'Private Package' : 'Public Package'"
							>
								<path
									v-if="thisPackage.isPrivate"
									fill-rule="evenodd"
									d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
									clip-rule="evenodd"
								/>
								<path
									v-else
									d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
								/>
							</svg>
							{{ thisPackage.title }}
							<span
								v-if="thisPackage.latestVersion.version != ''"
								class="tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-sm tw-font-medium tw-bg-blue-300 tw-text-gray-800 tw-align-middle"
							>
								{{ thisPackage.latestVersion.version }}
							</span>
						</h1>
						<div class="tw-mt-1 tw-flex tw-flex-col sm:tw-flex-row sm:tw-flex-wrap sm:tw-mt-0 sm:tw-space-x-8">
							<div class="tw-mt-1 tw-flex tw-items-center tw-text-sm tw-font-bold tw-text-gray-500">
								{{ thisPackage.summary }}
							</div>
						</div>
						<div class="tw-mt-1 tw-flex tw-flex-col sm:tw-flex-row sm:tw-flex-wrap sm:tw-mt-0 sm:tw-space-x-8">
							<div class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
								<!-- Heroicon name: solid/archive -->
								<svg
									class="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400 tw-cursor-pointer"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									v-tooltip="'Storage Location'"
								>
									<path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
									<path
										fill-rule="evenodd"
										d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
										clip-rule="evenodd"
									/>
								</svg>
								<span
									v-tooltip="getLocation( thisPackage.location ) == 'External Source' ? thisPackage.location : ''"
									:class="{'tw-cursor-pointer' : getLocation( thisPackage.location ) == 'External Source'}"
									v-clipboard:copy="thisPackage.location"
									@click="getLocation( thisPackage.location ) == 'External Source' ? showNotification( 'info','Success','The storage location has been copied to your clipboard!' ) : ''"
								>
									{{ getLocation( thisPackage.location ) != "External Source" ? getLocation( thisPackage.location ) : "External Source" }}
								</span>
							</div>
							<div class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
								<!-- Heroicon name: solid/tag -->
								<svg
									class="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400 tw-cursor-pointer"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									v-tooltip="'Package Type'"
								>
									<path
										fill-rule="evenodd"
										d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
										clip-rule="evenodd"
									/>
								</svg>
								{{ thisPackage.typeName }}
							</div>
							<div class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
								<!-- Heroicon name: solid/download -->
								<svg
									class="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400 tw-cursor-pointer"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 24 24"
									stroke="currentColor"
									v-tooltip="'Installs'"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M13 10V3L4 14h7v7l9-11h-7z"
									/>
								</svg>
								{{ thisPackage.installs | formatNumber }}
							</div>
							<div class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500">
								<!-- Heroicon name: solid/calendar -->
								<svg
									class="tw-flex-shrink-0 tw-mr-1.5 tw-h-5 tw-w-5 tw-text-gray-400 tw-cursor-pointer"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
									v-tooltip="'Last Update'"
								>
									<path
										fill-rule="evenodd"
										d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
										clip-rule="evenodd"
									/>
								</svg>
								Last updated on {{ thisPackage.latestVersion.updatedDate | formatDate }}
								<a
									:href="'/@'+thisPackage.user.username"
									v-tooltip="'Updated by '+thisPackage.user.username"
								>
									<Avatar
										:email="thisPackage.user.email"
										:px="40"
										:size="5"
										custom-class="tw-ml-2"
									/>
								</a>
							</div>
						</div>
					</div>
					<div class="tw-mt-5 tw-flex xl:tw-mt-0 xl:tw-ml-4">
						<span>
							<button
								type="button"
								class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-50 focus:tw-ring-blue-500"
								@click="showEditPackage = true"
							>
								<!-- Heroicon name: solid/pencil -->
								<svg
									class="tw--ml-1 tw-mr-2 tw-h-5 tw-w-5 tw-text-gray-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
								</svg>
								Edit
							</button>
						</span>

						<span class="tw-ml-3">
							<a :href="'/view/'+thisPackage.slug">
								<button
									type="button"
									class="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-700 tw-bg-white hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-50 focus:tw-ring-blue-500"
								>
									<!-- Heroicon name: solid/eye -->
									<svg
										class="tw--ml-1 tw-mr-2 tw-h-5 tw-w-5 tw-text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="none"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
										<path
											fill-rule="evenodd"
											d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
											clip-rule="evenodd"
										/>
									</svg>
									View
								</button>
							</a>
						</span>

						<span class="tw-ml-3 tw-relative tw-z-0 tw-inline-flex tw-shadow-sm tw-rounded-md tw-divide-x tw-divide-blue-600">
							<!--
								Custom select controls like this require a considerable amount of JS to implement from scratch. We're planning
								to build some low-level libraries to make this easier with popular frameworks like React, Vue, and even Alpine.js
								in the near future, but in the mean time we recommend these reference guides when building your implementation:

								https://www.w3.org/TR/wai-aria-practices/#Listbox
								https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
							-->
							<div>
								<label
									id="listbox-label"
									class="tw-sr-only"
								>
									Change published status
								</label>
								<div class="tw-relative">
									<div
										:class="thisPackage.isActive ? 'tw-divide-green-600' : 'tw-divide-red-600'"
										class="tw-relative tw-z-0 tw-inline-flex tw-shadow-sm tw-rounded-md tw-divide-x"
									>
										<div
											:class="thisPackage.isActive ? 'tw-bg-green-500 tw-border-transparent' : 'tw-bg-red-500'"
											class="tw-pr-4 tw-border tw-py-2 tw-pl-3 tw-relative tw-inline-flex tw-items-center tw-rounded-l-md tw-shadow-sm tw-text-white"
										>
											<!-- Heroicon name: solid/arrow-up -->
											<svg
												class="tw-h-5 tw-w-5"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													v-if="thisPackage.isActive"
													fill-rule="evenodd"
													d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
													clip-rule="evenodd"
												/>
												<!-- Heroicon name: solid/arrow-down -->
												<path
													v-else
													fill-rule="evenodd"
													d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
													clip-rule="evenodd"
												/>
											</svg>
											<p class="tw-ml-2.5 tw-text-sm tw-font-medium">
												{{ thisPackage.isActive ? 'Published' : 'Unpublished' }}
											</p>
										</div>
										<button
											type="button"
											:class="thisPackage.isActive ? 'tw-bg-green-500 hover:tw-bg-green-600 focus:tw-ring-green-500' : 'tw-bg-red-500 hover:tw-bg-red-600 focus:tw-ring-red-500'"
											class="tw-relative tw-inline-flex tw-items-center tw-p-2 tw-rounded-r-md tw-text-sm tw-font-medium tw-text-white focus:tw-outline-none focus:tw-z-10 focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-50"
											aria-haspopup="listbox"
											aria-expanded="true"
											aria-labelledby="listbox-label"
											v-click-outside="() => (publishedDropdown = false)"
											@click="publishedDropdown = !publishedDropdown"
										>
											<span class="tw-sr-only">Change published status</span>
											<!-- Heroicon name: solid/chevron-down -->
											<svg
												class="tw-h-5 tw-w-5 tw-text-white"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												aria-hidden="true"
											>
												<path
													fill-rule="evenodd"
													d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
													clip-rule="evenodd"
												/>
											</svg>
										</button>
									</div>

									<!--
										Select popover, show/hide based on select state.

										Entering: ""
										From: ""
										To: ""
										Leaving: "transition ease-in duration-100"
										From: "opacity-100"
										To: "opacity-0"
									-->
									<ul
										v-if="publishedDropdown"
										class="tw-origin-top-right tw-absolute tw-left-0 tw-mt-2 tw--mr-1 tw-w-72 tw-rounded-md tw-shadow-lg tw-overflow-hidden tw-bg-white tw-divide-y tw-divide-gray-200 tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-left-auto sm:tw-right-0"
										tabindex="-1"
										role="listbox"
										aria-labelledby="listbox-label"
										aria-activedescendant="listbox-option-0"
									>
										<li
											class="tw-text-gray-900 tw-cursor-default tw-select-none tw-relative tw-p-4 tw-text-sm"
											:class="{ 'tw-bg-green-500' : thisPackage.isActive, 'tw-bg-red-500' : !thisPackage.isActive }"
											id="listbox-option-0"
											role="option"
										>
											<div class="tw-flex tw-flex-col">
												<div class="tw-flex tw-justify-between">
													<p class="tw-font-semibold tw-text-white">
														{{ thisPackage.isActive ? 'Published' : 'Unpublished' }}
													</p>
													<span class="tw-text-white">
														<!-- Heroicon name: solid/check -->
														<svg
															class="tw-h-5 tw-w-5"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 20 20"
															fill="currentColor"
															aria-hidden="true"
														>
															<path
																fill-rule="evenodd"
																d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
																clip-rule="evenodd"
															/>
														</svg>
													</span>
												</div>
												<span
													class="tw-text-white"
												>
													This is a {{ thisPackage.isPrivate ? 'private' : 'public' }} package and it's currently {{ thisPackage.isActive ? 'published' : 'unpublished' }}.
												</span>
											</div>
										</li>
										<li
											class="tw-text-gray-900 tw-select-none tw-relative tw-p-4 tw-text-sm"
											:class="thisPackage.numberOfVersions > 0 ? 'hover:tw-bg-gray-50 tw-cursor-pointer' : 'tw-bg-gray-100 tw-opacity-75 tw-cursor-not-allowed'"
											id="listbox-option-0"
											role="option"
										>
											<span>
												<div
													class="tw-flex tw-flex-col"
													:class="thisPackage.numberOfVersions > 0 ? 'hover:tw-text-gray-900' : ''"
													@click="openPublishingDialog(!thisPackage.isActive)"
													v-tooltip="thisPackage.numberOfVersions == 0 ? 'Create a package version first' : ''"
												>
													<div class="tw-flex tw-justify-between">
														<p class="tw-font-normal">
															{{ !thisPackage.isActive ? 'Publish' : 'Unpublish' }}
														</p>
													</div>
													<span class="tw-text-gray-500">
														{{ !thisPackage.isActive ? 'Publish' : 'Unpublish' }} this package now.
													</span>
												</div>
											</span>
										</li>
									</ul>
								</div>
							</div>
						</span>
					</div>
				</div>
			</header>

			<div class="tw-pb-8">
				<div class="tw-mx-auto sm:tw-px-6 lg:tw-px-8">
					<div class="tw-px-4">
						<!-- Tabs -->
						<div class="sm:tw-hidden">
							<label
								for="tabs"
								class="tw-sr-only"
							>
								Select a tab
							</label>
							<select
								v-model="currentTab"
								id="tabs"
								name="tabs"
								class="tw-mt-4 tw-block tw-w-full tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-300 focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500 sm:tw-text-sm tw-rounded-md"
							>
								<option v-if="false">
									Activity Log
								</option>

								<option value="versions">
									Versions
								</option>

								<option value="collaborators">
									Collaborators
								</option>

								<option value="subscribers">
									Subscribers
								</option>
							</select>
						</div>
						<div class="tw-hidden sm:tw-block">
							<div class="tw-border-b tw-border-gray-200">
								<nav
									class="tw-mt-2 tw--mb-px tw-flex tw-space-x-8"
									aria-label="Tabs"
								>
									<!-- Activity Log goes here -->
									<span
										:class="currentTab == 'versions' ? 'tw-border-blue-500 tw-text-blue-600' : 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-200'"
										class="tw-whitespace-nowrap tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-cursor-pointer"
										@click="currentTab = 'versions'"
									>
										Versions
										<span
											:class="currentTab == 'versions' ? 'tw-bg-blue-100 tw-text-blue-600' : 'tw-bg-gray-100 tw-text-gray-900'"
											class="tw-hidden tw-ml-2 tw-py-0.5 tw-px-2.5 tw-rounded-full tw-text-xs tw-font-medium md:tw-inline-block"
										>
											{{ thisPackage.numberOfVersions }}
										</span>
									</span>

									<span
										:class="currentTab == 'collaborators' ? 'tw-border-blue-500 tw-text-blue-600' : 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-200'"
										class="tw-whitespace-nowrap tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-cursor-pointer"
										@click="currentTab = 'collaborators'"
									>
										Collaborators
										<span
											:class="currentTab == 'collaborators' ? 'tw-bg-blue-100 tw-text-blue-600' : 'tw-bg-gray-100 tw-text-gray-900'"
											class="tw-hidden tw-ml-2 tw-py-0.5 tw-px-2.5 tw-rounded-full tw-text-xs tw-font-medium md:tw-inline-block"
										>
											{{ thisPackage.numberOfCollaborators }}
										</span>
									</span>

									<span
										:class="currentTab == 'subscribers' ? 'tw-border-blue-500 tw-text-blue-600' : 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-200'"
										class="tw-whitespace-nowrap tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm tw-cursor-pointer"
										@click="currentTab = 'subscribers'"
									>
										Subscribers
										<span
											:class="currentTab == 'subscribers' ? 'tw-bg-blue-100 tw-text-blue-600' : 'tw-bg-gray-100 tw-text-gray-900'"
											class="tw-hidden tw-ml-2 tw-py-0.5 tw-px-2.5 tw-rounded-full tw-text-xs tw-font-medium md:tw-inline-block"
										>
											{{ thisPackage.numberOfSubscribers }}
										</span>
									</span>
								</nav>
							</div>
						</div>
					</div>

					<div class="tw-px-4">
						<!-- Activity Log Section -->
						<div v-if="false">
							<p>Activity Log Goes Here</p>
						</div>

						<!-- Versions Section -->
						<div v-if="currentTab == 'versions'">
							<div
								v-if="thisPackage.numberOfVersions == 0"
								class="tw-bg-white tw-shadow sm:tw-rounded-lg tw-mt-3"
							>
								<div class="tw-px-4 tw-py-5 sm:tw-p-6">
									<h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
										New Package
									</h3>
									<div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
										<p class="tw-text-sm">
											This is a new package and there are no versions yet. Go ahead and create your very first version for this package that way you can publish it on FORGEBOX.
										</p>
									</div>
									<div class="tw-mt-3 tw-text-sm">
										<a
											:href="!isOrg ? `/myaccount/package/${thisPackage.slug}/newVersion` : `/orgs/${organization.slug}/package/${thisPackage.slug}/newVersion`"
											class="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500"
										>
											Create first version now <span aria-hidden="true">→</span>
										</a>
									</div>
								</div>
							</div>
							<div v-else>
								<versions
									:this-package="thisPackage"
									:organization="organization"
									:is-org="isOrg"
								/>
							</div>
						</div>

						<!-- Collaborators Section -->
						<div v-else-if="currentTab == 'collaborators'">
							<div
								v-if="thisPackage.numberOfCollaborators == 0"
								class="tw-bg-white tw-shadow sm:tw-rounded-lg tw-mt-3"
							>
								<div class="tw-px-4 tw-py-5 sm:tw-p-6">
									<h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
										No collaborators yet
									</h3>
									<div v-if="thisPackage.isActive">
										<div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
											<p class="tw-text-sm">
												This package does not have any collaborator yet. Go ahead and add collaborators so other users can contribute on this package.
											</p>
										</div>
										<div
											v-if="canInviteToCollaborate"
											class="tw-mt-3 tw-text-sm"
										>
											<span
												class="tw-font-medium tw-text-blue-600 hover:tw-text-blue-500 tw-cursor-pointer"
												@click="showCollaboratorsForm = true"
											>
												Add Collaborator <span aria-hidden="true">→</span>
											</span>
										</div>
									</div>
									<div v-else>
										<div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
											<p class="tw-text-sm">
												This package is not published. You cannot add collaborators on unpublished packages. Make sure you publish your package first.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								<collaborators
									:this-package="thisPackage"
									:show-collaborators-form="true"
									:user="user"
									:organization="organization"
									:is-org="isOrg"
								/>
							</div>
						</div>

						<!-- Subscribers Section -->
						<div v-else-if="currentTab == 'subscribers'">
							<div
								v-if="thisPackage.numberOfSubscribers == 0"
								class="tw-bg-white tw-shadow sm:tw-rounded-lg tw-mt-3"
							>
								<div class="tw-px-4 tw-py-5 sm:tw-p-6">
									<h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
										No subscribers yet
									</h3>
									<div class="tw-mt-2 tw-max-w-xl tw-text-sm tw-text-gray-500">
										<p class="tw-text-sm">
											There are no subscribers at the moment. Users who subscribe to this package will appear here.
										</p>
									</div>
								</div>
							</div>
							<div v-else>
								<subscribers
									:this-package="thisPackage"
									:user="user"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Versions from "./Versions/Index";
import Collaborators from "./Collaborators/Index";
import CollaboratorsForm from "./Collaborators/CollaboratorsForm";
import Subscribers from "./Subscribers/Index";
import EditPackage from "./Edit";
import ClickOutside from "vue-click-outside";
import swal from "sweetalert2";

export default {
	props : {
		thisPackage : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		packageTypes : {
			type     : Array,
			required : true,
			default  : function() {
				return [];
			}
		},
		hasPrivatePackages : {
			type     : Boolean,
			required : true,
			default  : false
		},
		canPublishFreePrivatePackage : {
			type     : Boolean,
			required : false,
			default  : false
		},
		user : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : false,
			default  : function() {
				return {};
			}
		},
		isOrg : {
			type     : Boolean,
			required : false,
			default  : false
		},
		isAdmin : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	components : { EditPackage, Versions, Collaborators, CollaboratorsForm, Subscribers },
	directives : { ClickOutside },
	data() {
		return {
			currentTab            : "versions",
			publishedDropdown     : false,
			showEditPackage       : false,
			showCollaboratorsForm : false,
			notification          : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	mounted(){
	},
	filters  : {},
	computed : {
		menu() {
			return [
				{
					label : "Packages",
					url   : !this.isOrg ? "/myaccount/packages" : `orgs/${this.organization.slug}/packages`
				},
				{ label: this.thisPackage.title }
			];
		},
		canInviteToCollaborate() {
			return this.thisPackage.user.username == this.user.username;
		}
	},
	methods : {
		addCollaborator( collaboration ) {
			var app = this;
			app.thisPackage.collaborations.push( collaboration );
		},
		getLocation( location ){
			return location.toLowerCase() == "forgeboxstorage" ? "FORGEBOX Storage" : "External Source";
		},
		openPublishingDialog( status ) {
			var app = this;
			// validate state
			if ( app.thisPackage.numberOfVersions == 0 ){
				return;
			}
			app.notification.show = false;
			var action = status ? "publish" : "unpublish";

			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to " + action + " this package.",
				icon               : "question",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#297492",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					var url = !app.isOrg ? `/myaccount/packages/${this.thisPackage.entryID}/changeStatus/${status}` : `/orgs/${app.organization.slug}/packages/${this.thisPackage.entryID}/changeStatus/${status}`;
					app.$http.post( url )
						.then( ( response ) => {
							app.loading = false;
							if ( !response.data.error ) {
								app.thisPackage.isActive = status;
								app.notification = {
									type    : "success",
									title   : "Success",
									message : response.data.data,
									show    : true
								};
							} else {
								app.notification = {
									type    : "error",
									title   : "Error",
									message : "There was an error changing the status of this package",
									show    : true
								};
							}
						} ).catch( function( error ) {
							// handle error
							app.loading = false;
							app.errors = [ error.response.data.messages[0] ];
							app.notification = {
								type    : "error",
								title   : "Error",
								message : "Oops something went wrong. Please try again",
								show    : true
							};
						} );
				}
			} );
		},
		showNotification( type = "info", title= "Success", message ) {
			var app = this;
			app.notification = {
				type    : type,
				title   : title,
				message : message,
				show    : true
			};
			// Prepare the flash notifications component for the next request
			setTimeout( function(){ app.notification.show = false; }, 6000 );
		}
	}
};
</script>